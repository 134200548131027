import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ListGroup,Form, Button, Card } from "react-bootstrap";
import { addToCart, addBundleToCart, removeFromCart, addToCartUsingSku, addToCartAdmin } from "../actions/cartActions";
import { listProducts } from "../actions/productActions";
import { createOrderAdmin, printOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import PhoneInput from "react-phone-number-input";
import {
  CLOTHING,
  ELECTRONICS,
  FOOTWEAR,
  FURNITURE,
  SPORTS,
  GROCERY,
  FOOD,
  BOOKS,
  TOYS,
} from "../constants/categoryConstants";
import Message from "../components/Message";
import Loader from "../components/Loader";

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id;
  const params = new URLSearchParams(window.location.search);
  const pageNumber = match.params.pageNumber || 1;
  const type = params.get("type");

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const marketplaceId = localStorage.getItem('marketPlaceAddress') ? JSON.parse(localStorage.getItem('marketPlaceAddress')).marketplaceId : store._id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const [sku, setSku] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getGST = (category) => {
    switch (category) {
      case ELECTRONICS:
        return 18;
      case CLOTHING:
        return 12;
      case GROCERY:
        return 5;
      case FOOD:
        return 5;
      case FOOTWEAR:
        return 5;
      case SPORTS:
        return 12;
      case FURNITURE:
        return 12;
      case BOOKS:
        return 5;
      case TOYS:
        return 12;
      default:
        return 5;
    }
  };

  /* send whatsapp message from react js */

  const calculateTax = (item) => {
    // previousItem is the previous item in the cart previousItem.price is the price of the previous item
    const GST = getGST(item.category);
    return item.price - (item.price * 100) / (100 + GST);
  };

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  useEffect(() => {
    if (productId && type === "bundle") {
      dispatch(addBundleToCart(productId, qty));
    } else {
      if (productId) {
        dispatch(addToCart(productId, qty));
      }
    }
  }, [dispatch, productId, qty, type, sku]);

  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const { success: successDelete } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const { loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct } = productCreate;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success } = orderCreate;

  useEffect(() => {
    if (!userInfo?.role === "admin") {
      history.push("/login");
    }
    if (successCreate) {
      history.push(`/admin/cartscreen`);
    } else {
      dispatch(listProducts("", "", "", pageNumber, marketplaceId));
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdProduct, pageNumber, marketplaceId]);

  const addToCartUsingSkuHandler = (e) => {
    e.preventDefault();
    console.log(sku.length);
    console.log("adding it to cart");
    dispatch(addToCartUsingSku(sku, qty));
    setSku("");
  };
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  //calculate price
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0));
  cart.shippingPrice = addDecimals(cart.itemsPrice > 50 ? 0 : 0);
  cart.taxPrice = cart.cartItems.reduce((acc, item) => acc + calculateTax(item), 0);
  cart.totalPrice = Number(cart.itemsPrice) + Number(cart.shippingPrice);

  const placeOrderHandler = () => {
    dispatch(
      createOrderAdmin(
        {
          orderItems: cart.cartItems,
          shippingAddress: {
            phone: phoneNumber,
            address: "Chitguppa",
            city: "Bidar",
            pinCode: "585412",
            country: "India",
          },
          paymentMethod: "Cash",
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
          phone: phoneNumber,
        },
        marketplaceId
      )
    );
  };

  const printOrderReceipt = () => {
    if (success) {
      dispatch(printOrder(order));
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
      focusOnSkuInput();
    }
  };

  const  focusOnSkuInput =() => {
    const skuInput = document.getElementById('sku');
  
    if (skuInput) {
      skuInput.focus();
    } else {
      console.warn("SKU input element with ID 'sku' not found.");
    }
  }


  useEffect(() => {


    if (success) {
      dispatch({
        type: CART_CLEAR_ITEMS,
      });
      localStorage.removeItem("cartItems");
    }

    // eslint-disable-next-line
  }, [history, success]);
  return (
    <Row>
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="warning">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>

          <Col className="mt-4" md={12}>
            <Form className="mt-4" onSubmit={addToCartUsingSkuHandler} inline>
              <h3 style={{ color: "black" }}>Product SKU</h3>
              <Row>
                <Col>
                  <Form.Control
                    style={{ width: "16rem" }}
                    type="text"
                    autoFocus
                    value={sku}
                    name="sku"
                    id="sku"
                    onChange={(e) => setSku(e.target.value)}
                    placeholder="Add Product using sku..."
                    className="mr-sm-2 ml-sm-5"
                  ></Form.Control>
                </Col>
              </Row>
            </Form>
            {cartItems.length === 0 ? (
              <h4>
                Your cart is empty<Link to="/"> Go to homepage</Link>
              </h4>
            ) : (
              <ListGroup variant="flush">
                <Row
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                    fontWeight: "600",
                    color: "black",
                    marginRight: "4em",
                  }}
                >
                  {/*    <Col md={1}>Photo</Col> */}
                  <Col md={4}>Item</Col>
                  <Col md={2}>MRP</Col>
                  <Col md={2}>Price</Col>
                  <Col md={2}>Quantity</Col>
                  <Col md={1}>Delete</Col>
                </Row>
                {cartItems.map((item) => (
                  <ListGroup.Item key={item.product}>
                    <Row style={{ textAlign: "center", marginRight: "4em" }}>
                      {/* <Col md={1}>
                        <Image src={item.image} alt={item.name} fluid></Image>
                      </Col> */}
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          value={item.name}
                          onChange={(e) =>
                            dispatch(addToCartAdmin(item.product, item.qty, item.price, e.target.value, item))
                          }
                        />
                      </Col>
                      <Col md={2}>{item.mrp}</Col>
                      <Col md={2}>
                        <Form.Control
                          type="number"
                          value={item.price}
                          onChange={(e) =>
                            dispatch(addToCartAdmin(item.product, item.qty, Number(e.target.value), item.name, item))
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="number"
                          value={item.qty}
                          onChange={(e) => dispatch(addToCartAdmin(item.product, Number(e.target.value), item.price,item.name,item))}
                        />
                      </Col>
                      <Col md={1}  tabIndex={5}>
                        <Button tabIndex={5}
                          type="button"
                          variant="light"
                          id="delete"
                          onClick={() => removeFromCartHandler(item.product)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}

            <Form style={{ marginTop: "1.2rem" }}>
              <Form.Group controlId="phoneNumber">
                <Form.Label className="d-flex align-items-center">
                  <h4>Customer mobile number</h4>
                  <span className="text-danger ml-6">*</span>
                </Form.Label>
                <Row>
                  <Col>
                    <PhoneInput
                      defaultCountry="IN"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      placeholder="10 digit mobile number"
                    />
                  </Col>
                  <Col>
                    <Button
                      type="button"
                      variant="secondary"
                      id="proceed"
                      disabled={cartItems.length === 0 || Number(phoneNumber?.length) < 7 || phoneNumber === undefined}
                      onClick={placeOrderHandler}
                    >
                      Order
                    </Button>
                  </Col>
                  <Col>
                    <Button type="button" variant="secondary" onClick={printOrderReceipt} disabled={!success}>
                      Print reciept
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>

            <Card className="mt-4">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>TotalItems : {cartItems.reduce((acc, item) => acc + item.qty, 0)}</h5>
                  Total price: ₹{" "}
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

        </>
      )}
    </Row>
  );
};

export default CartScreen;
