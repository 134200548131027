import React from "react";
import { getAllMarketPlaces } from "../../actions/marketActions.js";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useEffect } from "react";
const MarketPlaces = () => {
  const dispatch = useDispatch();
  const markets = useSelector((state) => state.marketPlaces);
  const { marketplaces, loading, error } = markets;

  useEffect(() => {
    dispatch(getAllMarketPlaces());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ textAlign: "center" }}
            className="mt-3"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>market Name</th>
                <th>Company</th>
                <th>Address</th>
                <th> Pin Codes</th>
                <th>Edit</th>
                <th>View</th>
              </tr>
            </thead>
            {marketplaces && (
              <tbody>
                {marketplaces.map((market, index) => (
                  <tr key={market._id}>
                    <td key={market._id}>{index}</td>
                    <td>{market.name}</td>
                    <td>{market.company}</td>
                    <td>{market.address}</td>
                    <td>
                      {market &&
                        market.pinCodes.map((pinCode) => {
                          return <span>{pinCode}, </span>;
                        })}{" "}
                    </td>
                    <td>
                      <LinkContainer
                        to={`/superadmin/marketplace/${market._id}/edit`}
                      >
                        <Button variant="light" className="btn-sm">
                          <i
                            style={{ color: "blue" }}
                            className="fas fa-edit"
                          ></i>
                        </Button>
                      </LinkContainer>
                    </td>
                    <td>
                      <LinkContainer
                        to={`/superadmin/marketplace/${market._id}/view`}
                      >
                        <Button variant="light" className="btn-sm">
                          <i
                            style={{ color: "blue" }}
                            className="fas fa-edit"
                          ></i>
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </>
      )}
    </>
  );
};

export default MarketPlaces;
